import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import axios from 'axios'

import 'animate.css'
import 'video.js/dist/video-js.css'
import i18n from './i18n'
import {mapActions, mapGetters, mapState} from 'vuex';

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json'

Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.mixin({
  methods: {
    ...mapActions([
      'snackbarAction',
    ]),
    ...mapGetters([
      'getSnackbar',
    ]),
  },
  computed: {
    ...mapState([
      'snackbar',
    ]),
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
