<template>
  <v-app>
    <v-main>
      <app-bar/>
      <router-view/>
      <snack-bar/>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Footer from '@/components/Footer.vue'
import SnackBar from '@/components/Snackbar.vue'

export default {
  name: 'App',
  data: () => ({}),
  components: {
    AppBar,
    Footer,
    SnackBar
  },
  created() {
    if (!localStorage.getItem('locale')) {
      const availableLocales = this.$i18n.availableLocales
      const userLanguage = window.navigator.language

      for (const locale of availableLocales) {
        if (userLanguage.includes(locale)) {
          this.$i18n.locale = locale
          localStorage.setItem('locale', locale)
          break
        }
      }
    }
  }
};
</script>
