var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"color":"#129FDB","dark":"","elevation":"5"}},[_c('v-toolbar-title',[_c('v-img',{attrs:{"src":require("@/assets/images/360&1_white.png"),"alt":"360&1","width":"128"}})],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('additional_infos'))+" ")]),_c('v-card-text',[_c('div',[_vm._v(_vm._s(_vm.$t('additional_infos_2'))+" "),_c('b',[_vm._v("(+33) 9 80 80 00 02")])]),_c('div',{staticClass:"font-weight-light font-italic mt-2"},[_vm._v(_vm._s(_vm.$t('additional_infos_3')))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Ok ")])],1)],1)],1),_c('locale-changer')],1),_c('v-breadcrumbs',{attrs:{"items":[{
      text: _vm.$t('generals_informations'),
      disabled: !this.isFirstStep,
    },
    {
      text: _vm.$t('rib_upload'),
      disabled: !this.isSecondStep,
    },
    {
      text: _vm.$t('summary'),
      disabled: !this.isThirdStep,
    },
    {
      text: _vm.$t('completed'),
      disabled: !this.isFourthStep,
    }],"divider":">"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }