<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
            color="primary"
            dark
            v-on="on"
        >
          <img :src="getLocaleFlag" :alt="getLocale" style="width: 32px;">
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="!enActive" @click="switchLocale('en')">
          <img src="@/assets/images/en.png" alt="en" style="width: 32px;">
        </v-list-item>
        <v-list-item v-if="!frActive" @click="switchLocale('fr')">
          <img src="@/assets/images/fr.png" alt="fr" style="width: 32px;">
        </v-list-item>
        <v-list-item v-if="!esActive" @click="switchLocale('es')">
          <img src="@/assets/images/es.png" alt="es" style="width: 32px;">
        </v-list-item>
        <v-list-item v-if="!itActive" @click="switchLocale('it')">
          <img src="@/assets/images/it.png" alt="it" style="width: 32px;">
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  data: () => ({}),
  created() {
    const locale = localStorage.getItem('locale')
    if (locale) {
      this.$i18n.locale = locale
    }
  },
  methods: {
    switchLocale: function (locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
    }
  },
  computed: {
    enActive: function () {
      return this.$i18n.locale === 'en'
    },
    frActive: function () {
      return this.$i18n.locale === 'fr'
    },
    esActive: function () {
      return this.$i18n.locale === 'es'
    },
    itActive: function () {
      return this.$i18n.locale === 'it'
    },
    getLocale: function () {
      return this.$i18n.locale
    },
    getLocaleFlag: function () {
      return require(`../assets/images/${this.$i18n.locale}.png`)
    },
    notSelectedLocales: function () {
      return ['en', 'fr', 'es', 'it'].filter((element) => {
        return element !== this.getLocale
      })
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
