<template>
  <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      app
  >
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn
          text
          v-bind="attrs"
          @click="snackbar.active = false"
      >
        <v-icon>mdi-close-box-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

</template>

<script>
export default {
  name: "Snackbar"
}
</script>

<style scoped>

</style>
