<template>
  <v-container v-if="ready">
    <v-row v-if="hasErrors" dense>
      <v-col cols="12" md="4" offset-md="4">
        <v-alert
            border="left"
            type="error"
            elevation="2"
        >
          <div class="font-weight-bold">
            {{ $t('an_error_occured_while_signing') }}
          </div>
          <div v-if="getErrors && Array.isArray(getErrors) && getErrors.length > 0">
            <ul>
              <li v-for="error in getErrors" :key="error.property">
                {{ error.property }} : {{ error.message }}
              </li>
            </ul>
          </div>
          <div v-else>
            {{ getErrors }}
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense v-if="frActive || enActive">
      <v-col cols="12" md="6" offset-md="3" class="mb-6 mt-2">
        <video-player :options="getVideoOptions"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="8" offset-md="2" class="mb-6 mt-2">
        <v-alert
            prominent
            text
            type="info"
            v-html="$t('info_first_step')"
        >
        </v-alert>
      </v-col>
    </v-row>
    <v-form
        ref="form"
    >
      <v-row dense>
        <v-col cols="12" md="8" offset-md="2" class="mb-6 mt-2">
          <h3 class="text-h4">
            {{ $t('your_informations') }}<span class="grey--text text--lighten-1">...</span>
          </h3>
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col
                cols="12"
                md="1"
                offset-md="2"
            >
              <v-select
                  required
                  v-model="title"
                  :items="[
                      {
                        text: $t('mr'),
                        value: 1
                      },
                      {
                        text: $t('mrs'),
                        value: 2
                      },
                      {
                        text: $t('miss'),
                        value: 3
                      }
                  ]"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            cols="12"
            md="3"
            offset-md="2"
        >
          <v-text-field
              v-model="firstName"
              :rules="nameRules"
              :label="$t('firstname')"
              append-icon="mdi-account-box-outline"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="3"
        >
          <v-text-field
              v-model="lastName"
              :rules="nameRules"
              :label="$t('lastname')"
              append-icon="mdi-account-box-outline"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="2"
        >
          <v-menu
              v-model="birthDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="birthDate"
                  :label="$t('birthdate')"
                  :rules="requiredRule"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="birthDate"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1910-01-01"
                color="#129FDB"
                @input="birthDatePicker = false"
                :locale="$t('date-picker')"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
            cols="12"
            md="4"
            offset-md="2"
        >
          <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('email_address')"
              append-icon="mdi-email-outline"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <vue-tel-input-vuetify
              v-model="phoneNumber"
              @input="onInputPhone"
              :label="$t('phone')"
              placeholder=""
              required
              :error="!phoneValid"
              defaultCountry="FR"
          ></vue-tel-input-vuetify>
        </v-col>

        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-text-field
              v-model="address"
              :label="$t('address')"
              :rules="requiredRule"
              append-icon="mdi-home-city-outline"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-text-field
              v-model="addressMore"
              append-icon="mdi-information-outline"
          >
            <template v-slot:label>
              <div>
                {{ $t('additional_address') }} <small>({{ $t('optional') }})</small>
              </div>
            </template>
          </v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="3"
            offset-md="2"
        >
          <country-search v-model="country"/>
        </v-col>

        <v-col
            cols="12"
            md="2"
            offset-md="1"
        >
          <postal-search v-model="postalCode" :country="country"/>
        </v-col>

        <v-col
            cols="12"
            md="2"
        >
          <city-search v-model="city" :country="country"/>
        </v-col>

        <v-col cols="12">
          <v-row align="center" dense>
            <v-col
                cols="12"
                md="3"
                offset-md="2"
            >
              {{ $t('have_Google_Analytics') }}
            </v-col>
            <v-col
                cols="12"
                md="2"
            >

              <v-select
                  required
                  v-model="google_analytics"
                  :items="[
                      {
                        text: $t('idk'),
                        value: 0,
                      },
                      {
                        text: $t('No'),
                        value: 1
                      },
                      {
                        text: $t('Yes'),
                        value: 2
                      }
                  ]"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row align="center" dense>
            <v-col
                cols="12"
                md="3"
                offset-md="2"
            >
                {{ $t('have_Google_Search_Console') }}
            </v-col>
            <v-col
                cols="12"
                md="2"
            >
              <v-select
                  required
                  v-model="google_search_console"
                  :items="[
                      {
                        text: $t('idk'),
                        value: 0,
                      },
                      {
                        text: $t('No'),
                        value: 1
                      },
                      {
                        text: $t('Yes'),
                        value: 2
                      }
                  ]"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="8" offset-md="2" class="mb-6 mt-6">
          <h3 class="text-h4">
            {{ $t('your_establishment') }}<span class="grey--text text--lighten-1">...</span>
          </h3>
        </v-col>
        <v-col cols="12" md="8" offset-md="2" class="mt-2 mb-2">
          <v-subheader class="font-weight-bold">{{ $t('contact_of_your_establishment') }}</v-subheader>
        </v-col>
        <v-col
            cols="12"
            md="4"
            offset-md="2"
        >
          <v-text-field
              v-model="establishmentName"
              :label="$t('name_of_your_establishment')"
              append-icon="mdi-text"
              :rules="requiredRule"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              v-model="establishmentEmail"
              :rules="emailRules"
              :label="$t('contact_email_address')"
              append-icon="mdi-email-outline"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-text-field
              v-model="establishmentWebsite"
              :rules="websiteRule"
              :label="$t('your_establishment_website')"
              placeholder="https://www.google.fr/"
              append-icon="mdi-web"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="8" offset-md="2" class="mt-2 mb-2">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" md="8" offset-md="2" class="mt-2 mb-2">
          <v-subheader class="font-weight-bold">{{ $t('category_of_your_establishment') }}</v-subheader>
        </v-col>

        <v-col cols="12" md="4" offset-md="2">
          <v-select
              :label="$t('type_of_establishment')"
              required
              v-model="establishmentType"
              :items="[
                  {
                    text: $t('hotel'),
                    value: 1
                  },
                  {
                    text: $t('b&b'),
                    value: 2
                  },
                  {
                    text: $t('cottage'),
                    value: 3
                  },
                  {
                    text: $t('camping'),
                    value: 4
                  },
                  {
                    text: $t('seasonal_rental'),
                    value: 5
                  },
                  {
                    text: $t('other'),
                    value: 6
                  }
              ]"
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-select
              :label="$t('number_of_rooms')"
              required
              v-model="establishmentRooms"
              :items="[
                  {
                    text: $t('less_than_x', { number: 10 }),
                    value: 1
                  },
                  {
                    text: $t('between_x_and_y', { number_x: 10, number_y: 20 }),
                    value: 2
                  },
                  {
                    text: $t('between_x_and_y', { number_x: 20, number_y: 30 }),
                    value: 3
                  },
                  {
                    text: $t('between_x_and_y', { number_x: 30, number_y: 40 }),
                    value: 4
                  },
                  {
                    text: $t('more_than_x', { number: 40 }),
                    value: 5
                  }
              ]"
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-select
              :label="$t('minimum_price_per_night')"
              required
              v-model="establishmentPrices"
              :items="[
                  {
                    text: $t('less_than_x_currency', { number: 50, currency: '€' }),
                    value: 1
                  },
                  {
                    text: $t('between_x_and_y_currency', { number_x: 50, number_y: 100, currency: '€' }),
                    value: 2
                  },
                  {
                    text: $t('between_x_and_y_currency', { number_x: 100, number_y: 150, currency: '€' }),
                    value: 3
                  },
                  {
                    text: $t('between_x_and_y_currency', { number_x: 150, number_y: 200, currency: '€' }),
                    value: 4
                  },
                  {
                    text: $t('more_than_x_currency', { number: 200, currency: '€' }),
                    value: 5
                  }
              ]"
          ></v-select>
        </v-col>

        <v-col cols="12" md="8" offset-md="2" class="mt-2 mb-2">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" md="8" offset-md="2" class="mt-2 mb-2">
          <v-subheader class="font-weight-bold">{{ $t('location_of_your_establishment') }}</v-subheader>
        </v-col>

        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-text-field
              v-model="establishmentAddress"
              :label="$t('address_of_your_establishment')"
              append-icon="mdi-home-city-outline"
              :rules="requiredRule"
              required
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-text-field
              v-model="establishmentAddressMore"
              append-icon="mdi-information-outline"
          >
            <template v-slot:label>
              <div>
                {{ $t('additional_address') }} <small>({{ $t('optional') }})</small>
              </div>
            </template>
          </v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="3"
            offset-md="2"
        >
          <country-search v-model="establishmentCountry" :country="establishmentCountry"/>
        </v-col>
        <v-col
            cols="12"
            md="2"
            offset-md="1"
        >
          <postal-search v-model="establishmentPostalCode" :country="establishmentCountry"/>
        </v-col>

        <v-col
            cols="12"
            md="2"
        >
          <city-search v-model="establishmentCity" :country="establishmentCountry"/>
        </v-col>
        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-text-field
              :value="getEstablishmentCode"
              :label="$t('amenitiz_code_of_your_establishment')"
              append-icon="mdi-code-tags"
              :hint="$t('data_transmitted_by_pms')"
              persistent-hint
              disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="valid !== null && !this.valid" cols="12" md="4" offset-md="4">
          <v-alert border="left" type="error" elevation="2">{{ $t('please_correct_the_form') }}</v-alert>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
              class="ma-2"
              style="color: white;"
              :loading="saving"
              :disabled="saving"
              color="#129FDB"
              @click="next"
          >
            {{ $t('next') }}&nbsp;<v-icon>mdi-arrow-right</v-icon>
            <template v-slot:loader>
              <v-icon class="animate__heartBeat">mdi-content-save-outline</v-icon>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <v-container v-else>
    <v-row
        dense
    >
      <v-col cols="12" md="10" offset-md="1">
        <v-alert
            dense
            outlined
            type="error"
        >
          {{ $t('access_denied') }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PostalSearch from '@/components/PostalSearch';
import CitySearch from '@/components/CitySearch';
import CountrySearch from '@/components/CountrySearch';
import VideoPlayer from "@/components/VideoPlayer";

export default {
  name: 'FirstStep',
  components: {
    VideoPlayer,
    PostalSearch,
    CitySearch,
    CountrySearch
  },
  data: () => ({
    valid: null,
    videoOptions: {
      autoplay: true,
      controls: true,
      fluid: true,
      sources: [
        {
          src: 'https://drop.datavault.tech/f.php?h=2EFs0xYV&p=1',
          type: 'video/mp4'
        }
      ]
    },
    ready: false,
    birthDatePicker: null,
    saving: false,
    title: 1,
    firstName: null,
    lastName: null,
    birthDate: null,
    email: null,
    phone: {
      number: "",
      valid: false,
      country: null
    },
    phoneNumber: null,
    address: null,
    addressMore: null,
    postalCode: null,
    city: null,
    country: null,
    establishmentType: 1,
    establishmentRooms: 1,
    establishmentPrices: 1,
    establishmentCode: null,
    establishmentName: null,
    establishmentEmail: null,
    establishmentWebsite: null,
    establishmentAddress: null,
    establishmentAddressMore: null,
    establishmentPostalCode: null,
    establishmentCity: null,
    establishmentCountry: null,
    google_analytics: 0,
    google_search_console: 0,
    nameRules: [],
    emailRules: [],
    requiredRule: [],
    websiteRule: []
  }),
  mounted() {
    this.init()
    this.$store.commit('isLoading', true)
    const informations = localStorage.getItem('informations') ? JSON.parse(localStorage.getItem('informations')) : null
    const establishment = localStorage.getItem('establishment') ? JSON.parse(localStorage.getItem('establishment')) : null
    if (informations) {
      this.title = informations.title
      this.firstName = informations.firstName
      this.lastName = informations.lastName
      this.birthDate = informations.birthDate
      this.email = informations.email
      this.phoneNumber = informations.phoneNumber
      this.address = informations.address
      this.addressMore = informations.addressMore
      this.postalCode = informations.postalCode
      this.city = informations.city
      this.country = informations.country
    }
    if (establishment) {
      this.establishmentName = establishment.establishmentName
      this.establishmentEmail = establishment.establishmentEmail
      this.establishmentWebsite = establishment.establishmentWebsite
      this.establishmentAddress = establishment.establishmentAddress
      this.establishmentAddressMore = establishment.establishmentAddressMore
      this.establishmentPostalCode = establishment.establishmentPostalCode
      this.establishmentCity = establishment.establishmentCity
      this.establishmentCountry = establishment.establishmentCountry
      this.establishmentType = establishment.establishmentType
      this.establishmentRooms = establishment.establishmentRooms
      this.establishmentPrices = establishment.establishmentPrices
    }
    setTimeout(() => {
      this.$store.commit('isLoading', false)
    }, 500)
  },
  methods: {
    init: function () {
      const hotel_id = this.$route.query.hotel_id

      if (hotel_id) {
        localStorage.setItem('establishmentCode', hotel_id)
      }

      const saved_hotel_id = localStorage.getItem('establishmentCode')

      if (saved_hotel_id) {
        this.ready = true
      }

      this.nameRules = [
        v => !!v || this.$t('this_field_is_required')
      ]
      this.emailRules = [
        v => !!v || this.$t('this_field_is_required'),
        v => /.+@.+/.test(v) || this.$t('invalid_email_address'),
      ]
      this.requiredRule = [
        v => !!v || this.$t('this_field_is_required')
      ]
      this.websiteRule = [
        v => !!v || this.$t('this_field_is_required'),
        v => /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/i.test(v) || this.$t('incorrect_site_address')
      ]
    },
    next: function () {
      this.valid = this.$refs.form.validate() && this.phoneValid
      if (this.valid) {
        this.saving = true
        this.save()
        setTimeout(() => {
          this.saving = false
          this.$store.commit('nextStep')
        }, 500)
      }
    },
    save: function () {
      this.$store.commit('setErrors', [])
      localStorage.setItem('informations', JSON.stringify({
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        birthDate: this.birthDate,
        email: this.email,
        phone: this.phone,
        phoneNumber: this.phoneNumber,
        address: this.address,
        addressMore: this.addressMore,
        postalCode: this.postalCode,
        city: this.city,
        country: this.country,
        google_analytics: this.google_analytics,
        google_search_console: this.google_search_console
      }))
      localStorage.setItem('establishment', JSON.stringify({
        establishmentCode: localStorage.getItem('establishmentCode'),
        establishmentName: this.establishmentName,
        establishmentEmail: this.establishmentEmail,
        establishmentWebsite: this.establishmentWebsite,
        establishmentAddress: this.establishmentAddress,
        establishmentAddressMore: this.establishmentAddressMore,
        establishmentPostalCode: this.establishmentPostalCode,
        establishmentCity: this.establishmentCity,
        establishmentCountry: this.establishmentCountry,
        establishmentType: this.establishmentType,
        establishmentRooms: this.establishmentRooms,
        establishmentPrices: this.establishmentPrices
      }))
    },
    onInputPhone: function (formattedNumber, {number, valid, country}) {
      this.phoneNumber = formattedNumber
      this.phone.number = number.international
      this.phone.valid = valid
      this.phone.country = country && country.name
    }
  },
  computed: {
    phoneValid: function () {
      return this.phone.valid
    },
    enActive: function () {
      return this.$i18n.locale === 'en'
    },
    frActive: function () {
      return this.$i18n.locale === 'fr'
    },
    esActive: function () {
      return this.$i18n.locale === 'es'
    },
    itActive: function () {
      return this.$i18n.locale === 'it'
    },
    getVideoOptions: function () {
      // DEFAULT LANGUAGE EN
      let videoSrc = 'https://drop.datavault.tech/f.php?h=1ElguByO&p=1'
      switch (this.$i18n.locale) {
        case 'fr':
          videoSrc = 'https://drop.datavault.tech/f.php?h=2EFs0xYV&p=1'
          break
      }
      return {
        ...this.videoOptions,
        sources: [
          {
            src: videoSrc,
            type: 'video/mp4'
          }
        ]
      }
    },
    getEstablishmentCode: function () {
      return localStorage.getItem('establishmentCode')
    },
    hasErrors: function () {
      return this.$store.getters.getErrors && this.$store.getters.getErrors.length > 0
    },
    getErrors: function () {
      return this.$store.getters.getErrors
    }
  },
  watch: {
    establishmentWebsite: {
      handler(value) {
        this.establishmentWebsite = value?.toLowerCase()
      },
      immediate: true
    },
    establishmentEmail: {
      handler(value) {
        this.establishmentEmail = value?.toLowerCase()
      },
      immediate: true
    },
    email: {
      handler(value) {
        this.email = value?.toLowerCase()
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
