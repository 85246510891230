<template>
  <v-footer
    app
    bottom
    color="#129FDB"
    padless
    fixed
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        color="#129FDB"
        class="py-4 text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} &copy; <strong>360and1.com</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {}
}
</script>

<style scoped>

</style>
