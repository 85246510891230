import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    loading: false,
    registerStep: 1,
    snackbar: {
      active: false,
      color: '',
      message: '',
      timeout: -1
    },
    errors: [],
  },
  getters: {
    getRegisterStep (state) {
      return state.registerStep
    },
    isLoading (state) {
      return state.loading
    },
    getSnackbar: (state) => {
      return state.snackbar
    },
    getErrors: (state) => {
      return state.errors
    }
  },
  mutations: {
    nextStep (state) {
      state.registerStep++;
    },
    previousStep (state) {
      state.registerStep--;
    },
    setToFirstStep (state) {
      state.registerStep = 1;
    },
    setToLastStep (state) {
      state.registerStep = 4;
    },
    isLoading (state, loading) {
      state.loading = loading
    },
    setSnackbar (state, snackbar) {
      state.snackbar = snackbar
    },
    setErrors(state, errors) {
      state.errors = errors
    }
  },
  actions: {
    snackbarAction({commit}, {message, color}) {
      commit('setSnackbar', {
        active: true,
        color,
        message
      })
    },
  },
  modules: {
  }
})
