<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="10" offset-md="1" class="mt-2">
        <h3 class="text-h4">
          {{ $t('summary') }}<span class="grey--text text--lighten-1">...</span>
        </h3>
      </v-col>
      <v-col  cols="12" md="10" offset-md="1" class="mb-6">
        <h5 class="subtitle-1">
          {{ $t('make_sure_you_have_entered_all_your_information_correctly') }}
        </h5>
      </v-col>
    </v-row>
    <v-row
        dense
        v-if="!dataFilled"
    >
      <v-col cols="12" md="10" offset-md="1">
        <v-alert
            dense
            outlined
            type="error"
        >
          Vous n'avez pas <strong>complété</strong> correctement vos <strong>informations</strong>, retournez à l'étape
          en question pour les corriger.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="data" dense>
      <v-col
          cols="12" md="10" offset-md="1"
      >
        <v-row dense>
          <v-col cols="12" md="6">
            <v-card elevation="2">
              <v-responsive :aspect-ratio="16/9">
                <v-card-title>
                  <v-icon>mdi-account</v-icon>&nbsp;{{ $t('your_informations') }}
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.informations.firstName"
                          :label="$t('firstname')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.informations.lastName"
                          :label="$t('lastname')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.informations.birthDate"
                          :label="$t('birthdate')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.informations.email"
                          :label="$t('email_address')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.informations.phoneNumber"
                          :label="$t('phone')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.informations.address"
                          :label="$t('address')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.informations.addressMore"
                          :label="$t('additional_address')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.informations.country"
                          :label="$t('country')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.informations.postalCode"
                          :label="$t('postal_code')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.informations.city"
                          :label="$t('city')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-responsive>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="2">
              <v-responsive :aspect-ratio="16/9">
                <v-card-title>
                  <v-icon>mdi-home-city</v-icon>&nbsp;{{ $t('your_establishment') }}
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.establishment.establishmentName"
                          :label="$t('name_of_your_establishment')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.establishment.establishmentEmail"
                          :label="$t('contact_email_address')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                          v-model="data.establishment.establishmentWebsite"
                          :label="$t('your_establishment_website')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.establishment.establishmentAddress"
                          :label="$t('address_of_your_establishment')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="data.establishment.establishmentAddressMore"
                          :label="$t('additional_address')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.establishment.establishmentCountry"
                          :label="$t('country')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.establishment.establishmentPostalCode"
                          :label="$t('postal_code')"
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                          v-model="data.establishment.establishmentCity"
                          :label="$t('city')"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" offset-md="3" v-if="!is_signed">
            <v-alert
                prominent
                type="error"
            >
              <v-row align="center">
                <v-col class="grow text-h6">
                  {{ $t('contract_not_right_language') }}
                </v-col>
                <v-col class="shrink">
                  <v-menu
                      bottom
                      offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="ma-2"
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ $t('click_here') }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="switchLocaleContract('en')">
                        <img src="@/assets/images/en.png" alt="en" class="mr-4" style="width: 32px;">
                        <v-list-item-title>{{ $t('english') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="switchLocaleContract('fr')">
                        <img src="@/assets/images/fr.png" alt="fr" class="mr-4" style="width: 32px;">
                        <v-list-item-title>{{ $t('french') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="switchLocaleContract('es')">
                        <img src="@/assets/images/es.png" alt="es" class="mr-4" style="width: 32px;">
                        <v-list-item-title>{{ $t('spanish') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="switchLocaleContract('it')">
                        <img src="@/assets/images/it.png" alt="it" class="mr-4" style="width: 32px;">
                        <v-list-item-title>{{ $t('italian') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="2">
              <v-responsive style="min-height: 100vh;">
                <v-card-title>
                  <v-icon>mdi-file-sign</v-icon>&nbsp;{{ $t('contract') }}
                </v-card-title>
                <v-card-text style="height: 100%">
                  <v-row dense style="height: 100%">
                    <v-col style="height: 100%">
                      <iframe style="height: 90%; width: 100%"
                              :src="iframe"></iframe>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="10" offset-md="1">
        <v-alert
            dense
            type="info"
        >
          {{ $t('please_sign') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="text-center">
        <v-btn
            class="ma-2"
            style="color: white;"
            :loading="savingPrevious"
            :disabled="savingPrevious || saving"
            color="orange"
            @click="previous"
        >
          <v-icon>mdi-arrow-left</v-icon>&nbsp;{{ $t('previous') }}
          <template v-slot:loader>
            <v-icon class="animate__heartBeat">mdi-content-save-outline</v-icon>
          </template>
        </v-btn>
        <v-btn
            class="ma-2"
            style="color: white;"
            :loading="saving"
            :disabled="savingPrevious || saving || !dataFilled || !is_signed"
            color="green"
            @click="next"
        >
          {{ $t('validate') }}&nbsp;<v-icon>mdi-check-all</v-icon>
          <template v-slot:loader>
            <v-icon class="animate__heartBeat">mdi-content-save-outline</v-icon>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Final-summary",
  data: () => ({
    dataFilled: false,
    data: null,
    savingPrevious: false,
    saving: false,
    is_signed: false,
    timer: null,
    procedureId: '',
    iframe: '',
    requiredRule: [
      v => !!v || 'Ce champ est obligatoire'
    ]
  }),
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      clearInterval(this.timer)
      this.$store.commit('isLoading', true)
      const informations = localStorage.getItem('informations') ? JSON.parse(localStorage.getItem('informations')) : null
      const establishment = localStorage.getItem('establishment') ? JSON.parse(localStorage.getItem('establishment')) : null
      const file_id = localStorage.getItem('file_id') ? JSON.parse(localStorage.getItem('file_id')) : null

      if (informations && establishment && file_id) {
        this.data = {
          informations,
          establishment,
          file_id
        }
        this.dataFilled = true
      }
      this.sendDocuments();
    },
    async switchLocaleContract(locale) {
      this.$store.commit('isLoading', true)
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)

      await this.regenerateContract()
      await this.init()
    },
    async regenerateContract() {
      await this.$http.post(`/sign/cancel`, { procedureId: this.procedureId })
    },
    sendDocuments() {
      this.$http.post(`/sign/`, {
        email: this.data.informations.email,
        firstName: this.data.informations.firstName,
        lastName: this.data.informations.lastName,
        ga: this.data.informations.google_analytics,
        gsc: this.data.informations.google_search_console,
        siteName: this.data.establishment.establishmentWebsite,
        siteCountry: this.data.establishment.establishmentCountry,
        phone: this.data.informations.phone.number,
        establishmentCode: this.data.establishment.establishmentCode,
        file_id: this.data.file_id,
        lang: this.$i18n.locale,
        establishmentType: this.data.establishment.establishmentType,
        establishmentRooms: this.data.establishment.establishmentRooms,
        establishmentPrices: this.data.establishment.establishmentPrices
      }).then(response => {
        this.procedureId = response.data.infos.procedureId;
        this.iframe = `${process.env.VUE_APP_YOUSIGN_URL}/procedure/sign?members=${response.data.infos.memberId}`
        this.timer = setInterval(this.refreshRequests, 5000)
        this.$store.commit('isLoading', false)
      }).catch(errors => {
        this.$store.commit('setErrors', errors.response.data)
        this.$store.commit('setToFirstStep')
        this.$store.commit('isLoading', false)
      })
    },
    refreshRequests() {
      if (this.procedureId) {
        this.$http.get(`/sign/status?procedureId=${this.procedureId}`).then(response => {
          this.is_signed = response.data.state === 1;
        })
      }
    },
    previous: function () {
      this.savingPrevious = true
      setTimeout(() => {
        this.savingPrevious = false
        this.$store.commit('previousStep')
      }, 500)
    },
    next: function () {
      this.saving = true
      setTimeout(async () => {
        this.saving = false
        await this.$router.push({name: 'Validate'})
      }, 500)
    }
  },
  watch: {
    is_signed: {
      handler(value) {
        if (this.is_signed && value) {
          this.next()
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.card-resume {
  border: 2px #129FDB solid !important;
}
</style>
