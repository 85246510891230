<template>
  <div class="home">
    <v-overlay
        v-show="isLoading"
        :value="isLoading"
    >
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <first-step v-if="getRegisterStep === 1"/>
    <second-step v-if="getRegisterStep === 2"/>
    <final-summary v-if="getRegisterStep === 3"/>
  </div>
</template>

<script>
import FirstStep from '@/components/FirstStep'
import SecondStep from '@/components/SecondStep'
import FinalSummary from '@/components/Summary'

export default {
  name: 'Home',
  components: {
    FirstStep,
    SecondStep,
    FinalSummary,
  },
  data: () => ({}),
  methods: {},
  computed: {
    getRegisterStep () {
      return this.$store.getters.getRegisterStep
    },
    isLoading () {
      return this.$store.getters.isLoading
    }
  }
}
</script>
