import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Success from '../views/Success.vue'
import Validate from '../views/Validate.vue'
import Error from '../views/Error.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/validate',
    name: 'Validate',
    component: Validate
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const hotel_id = to.query.hotel_id
  const saved_hotel_id = localStorage.getItem('establishmentCode')

  if (hotel_id && saved_hotel_id && hotel_id !== saved_hotel_id) {
    localStorage.removeItem('informations')
    localStorage.removeItem('establishment')
    localStorage.removeItem('establishmentCode')
    store.commit('setToFirstStep')
  }

  next()
})

export default router
