<template>
  <div class="home">
    <v-overlay
        v-show="isLoading"
        :opacity="1"
        :value="isLoading"
    >
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="8" offset-md="2" class="mt-6">
          <v-row dense>
            <v-col cols="12" class="text-center mb-16">
              <img src="@/assets/images/360&1.png" alt="360&1" style="width: 18%; min-width: 320px;">
            </v-col>
            <v-col cols="12" class="text-h3 text-center mb-4 animate__bounceIn">
              {{ $t('an_error_occurred') }}
            </v-col>
            <v-col cols="12" class="mb-4 text-h6 font-weight-light text-center">
              {{ $t('the_payment_process_failed') }}
            </v-col>
            <v-divider inset></v-divider>
            <v-col cols="12" class="mt-4 text-center">
                <v-btn
                    depressed
                    color="warning"
                    @click="next"
                >
                  {{ $t('return_to_homepage') }}
                </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Error',
  components: {
  },
  data: () => ({}),
  mounted() {
    this.$store.commit('setToLastStep')
  },
  computed: {
    getRegisterStep () {
      return this.$store.getters.getRegisterStep
    },
    isLoading () {
      return this.$store.getters.isLoading
    }
  },
  methods: {
    next: async function () {
      this.$store.commit('setToFirstStep')
      await this.$router.push({name: 'Home'})
    }
  }
}
</script>
