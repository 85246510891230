<template>
  <div class="home">
    <v-overlay
        :v-show="true"
        :opacity="1"
        :value="true"
    >
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Validate',
  components: {
  },
  data: () => ({}),
  async mounted () {
    setTimeout(async () => {
      try {
        const response = await this.$http.post('/create', {
          establishment: localStorage.getItem('establishment') ? JSON.parse(localStorage.getItem('establishment')) : null,
          informations: localStorage.getItem('informations') ? JSON.parse(localStorage.getItem('informations')) : null,
          lang: this.$i18n.locale,
          mail_subject: this.$t('mail_subject'),
        })
        if (response.status === 200) {
          await this.$router.push({name: 'Success'})
        } else {
          await this.$router.push({name: 'Error'})
        }
      } catch (error) {
        await this.$router.push({name: 'Error'})
      }
    }, 500)
  },
  computed: {
    getRegisterStep () {
      return this.$store.getters.getRegisterStep
    },
    isLoading () {
      return this.$store.getters.isLoading
    }
  }
}
</script>
