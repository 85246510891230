<template>
  <v-autocomplete
      v-model="country"
      :label="$t('country')"
      :items="countryEntries"
      :loading="countryLoading"
      :search-input.sync="countrySearch"
      :rules="requiredRule"
      item-text="description"
      item-value="structured_formatting.main_text"
      hide-no-data
      hide-selected
      required
  >
    <template v-slot:selection="{ item }">
      {{ item.structured_formatting.main_text }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.description }}
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "CountrySearch",
  props: ['value'],
  data: () => ({
    country: null,
    countryLoading: false,
    countryEntries: [],
    countrySearch: null,
    requiredRule: [],
  }),
  mounted() {
    this.requiredRule = [
      v => !!v || this.$t('this_field_is_required')
    ]
  },
  methods: {},
  computed: {},
  watch: {
    country: function (value) {
      this.$emit('input', value)
    },
    countrySearch: function (value) {
      if (this.countrySearch.length <= 0) {
        this.countryEntries = []
        return
      }
      if (this.countryLoading) return
      this.countryLoading = true

      this.$http.get(`/place/region/${value}?secret=fsgiqj5df4zreq61fF&filter=country&language=${this.$i18n.locale}`).then(response => {
        this.countryEntries = response.data.predictions;
        this.countryEntries.push({description: value, structured_formatting: {main_text: value}});
      })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.countryLoading = false
          })
    }
  }
}
</script>

<style scoped>

</style>
