<template>
  <div>
    <v-app-bar
        color="#129FDB"
        dark
        elevation="5"
    >
      <v-toolbar-title>
        <v-img src="@/assets/images/360&1_white.png" alt="360&1" width="128"/>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
          v-model="dialog"
          max-width="320"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              class="mr-2"
              dark
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('additional_infos') }}
          </v-card-title>
          <v-card-text>
            <div>{{ $t('additional_infos_2') }}&nbsp;<b>(+33) 9 80 80 00 02</b></div>
            <div class="font-weight-light font-italic mt-2">{{ $t('additional_infos_3') }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary darken-1"
                text
                @click="dialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <locale-changer/>
    </v-app-bar>
    <v-breadcrumbs
        :items="[{
        text: $t('generals_informations'),
        disabled: !this.isFirstStep,
      },
      {
        text: $t('rib_upload'),
        disabled: !this.isSecondStep,
      },
      {
        text: $t('summary'),
        disabled: !this.isThirdStep,
      },
      {
        text: $t('completed'),
        disabled: !this.isFourthStep,
      }]"
        divider=">"
    />
  </div>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger'

export default {
  name: "AppBar",
  data: () => ({
    dialog: false,
  }),
  components: {
    LocaleChanger,
  },
  mounted() {},
  methods: {},
  computed: {
    isFirstStep () {
      return this.$store.getters.getRegisterStep === 1 && !this.isFourthStep
    },
    isSecondStep () {
      return this.$store.getters.getRegisterStep === 2 && !this.isFourthStep
    },
    isThirdStep () {
      return this.$store.getters.getRegisterStep === 3 && !this.isFourthStep
    },
    isFourthStep () {
      return this.$route.name === 'Validate' || this.$route.name === 'Error' || this.$route.name === 'Success'
    }
  }
}
</script>

<style scoped>

</style>
