<template>
  <div class="home">
    <v-overlay
        v-show="isLoading"
        :opacity="1"
        :value="isLoading"
    >
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="10" offset-md="1" class="mt-6">
          <v-row align="center" justify="space-between">
            <v-col cols="12" class="text-h3 text-center mb-4 animate__bounceIn">
              {{ $t('thank_you!') }}
            </v-col>
            <v-col cols="12" class="mb-4 text-h6 font-weight-light text-center">
              <b>{{ $t('you_will_receive') }}</b> {{ $t('by_email_all_the_information') }} <a
                href="https://www.findthepax.fr/" target="_blank">www.findthepax.fr</a>
            </v-col>
            <v-col cols="12" class="mb-4 text-h6 font-weight-light text-center">
              {{ $t('please_activate') }}
            </v-col>
            <v-divider inset></v-divider>
            <v-col cols="12" class="mt-4 text-center">
              <v-btn
                  depressed
                  color="primary"
                  @click="next"
              >
                {{ $t('return_to_homepage') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data: () => ({
    skeleton: true,
  }),
  created() {
    this.$store.commit('setToFirstStep')
    let externalScript = document.createElement('script')

    externalScript.onload = () => {
      setTimeout(() => {
        this.skeleton = false
      }, 2000)
    }

    externalScript.async = true
    externalScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(externalScript)
  },
  computed: {
    getRegisterStep() {
      return this.$store.getters.getRegisterStep
    },
    isLoading() {
      return this.$store.getters.isLoading
    },
    isCalendlyActived() {
      return ['fr', 'en'].includes(this.$i18n.locale)
    },
    enActive: function () {
      return this.$i18n.locale === 'en'
    },
    frActive: function () {
      return this.$i18n.locale === 'fr'
    },
    esActive: function () {
      return this.$i18n.locale === 'es'
    },
    itActive: function () {
      return this.$i18n.locale === 'it'
    },
  },
  methods: {
    next: async function () {
      this.$store.commit('setToFirstStep')
      await this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style lang="scss" scoped>
.not-available {
  letter-spacing: 1px;
}
</style>
