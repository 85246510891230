<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="8" offset-md="2" class="mt-2">
        <h3 class="text-h4">
          {{ $t('rib_upload') }}<span class="grey--text text--lighten-1">...</span>
        </h3>
      </v-col>
      <v-col cols="12" md="8" offset-md="2" class="mb-6">
        <h5 class="subtitle-1">
          {{ $t('rib_verification') }}
        </h5>
      </v-col>
      <v-col
          cols="12"
          md="6"
          offset-md="3"
      >
        <v-row>
          <v-col cols="12">
            <template>
              <v-file-input
                v-model="file"
                accept="image/*,.pdf"
                :disabled="uploadDisabled"
                :label="$t('rib_upload')"
              ></v-file-input>
            </template>
            <v-alert
                prominent
                type="info"
                v-if="uploadDisabled"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{ $t('already_uploaded_file') }}
                </v-col>
                <v-col class="shrink">
                  <v-btn color="red" @click="resetFile">{{ $t('reset') }}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="text-center">
        <v-btn
            class="ma-2"
            style="color: white;"
            :loading="savingPrevious"
            :disabled="savingPrevious || saving"
            color="orange"
            @click="previous"
        >
          <v-icon>mdi-arrow-left</v-icon>&nbsp;{{ $t('previous') }}
          <template v-slot:loader>
            <v-icon class="animate__heartBeat">mdi-content-save-outline</v-icon>
          </template>
        </v-btn>
        <v-btn
            class="ma-2"
            style="color: white;"
            :loading="saving"
            :disabled="(nextDisabled && hasNoFileSelected) || saving"
            color="#129FDB"
            @click="next"
        >
          {{ $t('next') }}&nbsp;<v-icon>mdi-arrow-right</v-icon>
          <template v-slot:loader>
            <v-icon class="animate__heartBeat">mdi-content-save-outline</v-icon>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SecondStep',
  data: () => ({
    savingPrevious: false,
    uploadDisabled: false,
    saving: false,
    file: null,
    requiredRule: [
      v => !!v || 'Ce champ est obligatoire'
    ]
  }),
  async mounted () {
    this.$store.commit('isLoading', true)

    if (localStorage.getItem('file_id')) {
      const file_id = localStorage.getItem('file_id')
      const result = await this.$http.get(`/documents/${file_id}`)
      this.uploadDisabled = result.data.success
    }

    this.$store.commit('isLoading', false)
  },
  methods: {
    previous: function () {
      this.savingPrevious = true
      setTimeout(() => {
        this.savingPrevious = false
        this.$store.commit('previousStep')
      }, 500)
    },
    next: async function () {
      this.saving = true
      await this.save()
      setTimeout(() => {
        this.saving = false
        this.$store.commit('nextStep')
      }, 500)
    },
    save: async function () {
      if (this.file) {
        let formData = new FormData()
        formData.append('file', this.file)
        const response = await this.$http.post(`/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        localStorage.setItem('file_id', response.data.file_id)
        localStorage.setItem('filename', response.data.filename)
      }
    },
    resetFile: async function () {
      this.$store.commit('isLoading', true)

      if (localStorage.getItem('filename')) {
        this.uploadDisabled = false
        const filename = localStorage.getItem('filename')
        await this.$http.get(`/documents/${filename}/delete`)
      }

      setTimeout(() => {
        this.$store.commit('isLoading', false)
      }, 500)
    }
  },
  computed: {
    nextDisabled () {
      return this.savingPrevious || this.saving || !this.uploadDisabled
    },
    hasNoFileSelected () {
      return !this.file
    }
  },
}
</script>

<style scoped>

</style>
