<template>
  <v-autocomplete
      v-model="city"
      :label="$t('city')"
      :items="cityEntries"
      :loading="cityLoading"
      :search-input.sync="citySearch"
      :rules="requiredRule"
      item-text="description"
      item-value="structured_formatting.main_text"
      hide-no-data
      hide-selected
      required
  >
    <template v-slot:selection="{ item }">
      {{ item.structured_formatting.main_text }}
    </template>
    <template v-slot:item="{ item }">
      {{ item.description }}
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "CitySearch",
  props: ['value', 'country'],
  data: () => ({
    city: null,
    cityLoading: false,
    cityEntries: [],
    citySearch: null,
    requiredRule: [],
  }),
  mounted() {
    this.requiredRule = [
      v => !!v || this.$t('this_field_is_required')
    ]
  },
  methods: {},
  computed: {},
  watch: {
    city: function (value) {
      this.$emit('input', value)
    },
    citySearch: function (value) {
      if (this.citySearch.length <= 0) {
        this.cityEntries = []
        return
      }
      if (this.cityLoading) return
      this.cityLoading = true

      this.$http.get(`/place/city/${value}?secret=fsgiqj5df4zreq61fF&language=${this.$i18n.locale}${this.country ? '&country=' + this.country : ''}`).then(response => {
        this.cityEntries = response.data.predictions;
        this.cityEntries.push({description: value, structured_formatting: {main_text: value}});
      })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.cityLoading = false
          })
    }
  }
}
</script>

<style scoped>

</style>
